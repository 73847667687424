<template>
  <div class="ele-body">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="语音充值列表" name="first">
        <el-row :gutter="15" style="margin-top:10px">
          <!-- <el-row :gutter="15" style="margin-top:10px" v-if="voiceRecharge.admin_id !== 1"> -->
          <el-col :md="6" :sm="12" style="min-width: 240px;margin-top: 8px;">
            <el-card>
              <div slot="header" class="clearfix el-card__header1 boxTitle">
                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>通话分钟（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.voice_nums }}</div>
                <el-button style="float: right;" type="primary" @click="handlePurchase"
                           v-if="permission.includes('sys:voice:voicePurchase') && (voiceRecharge.admin_type == 4 || voiceRecharge.admin_type == 3)">购买语音套餐
                </el-button>
              </div>
            </el-card>
          </el-col>
          <el-col :md="6" :sm="12" style="min-width: 240px;margin-top: 8px;">
            <el-card>
              <div slot="header" class="clearfix el-card__header1 boxTitle">
                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>平台本月通话时长（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.month }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :md="6" :sm="12" style="min-width: 240px;margin-top: 8px;">
            <el-card class="box-card1">
              <div slot="header" class="clearfix el-card__header1 boxTitle">
                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>平台本周通话时长（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.week }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :md="6" :sm="12" style="min-width: 240px;margin-top: 8px;">
            <el-card class="box-card1">
              <div slot="header" class="clearfix el-card__header1 boxTitle">

                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>平台本日通话时长（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.today }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top:10px; display: none" v-if="voiceRecharge.admin_id == 1">
          <el-col :md="8" :sm="12">
            <el-card>
              <div slot="header" class="clearfix el-card__header1 boxTitle">
                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>平台本月通话时长（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.month }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :md="8" :sm="12">
            <el-card class="box-card1">
              <div slot="header" class="clearfix el-card__header1 boxTitle">

                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>平台本周通话时长（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.week }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :md="8" :sm="12">
            <el-card class="box-card1">
              <div slot="header" class="clearfix el-card__header1 boxTitle">

                <div class="d-flex f-item">
                  <div class="blueLine">|</div>
                  <span>平台本日通话时长（分）</span>
                </div>
              </div>
              <div class="orderImgBox">
                <div class="orderImgBoxText">{{ voiceRecharge.today }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-card class="box-card1" style="margin-top:12px">
          <div class="my-headline">充值记录</div>
          <!-- 数据表格 -->
          <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                          :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" fixed="left"/>
              <el-table-column type="index" :index="index" label="序号" width="60" align="center"
                               fixed="left" show-overflow-tooltip/>
                               
                               
            
              
              
              
              <el-table-column prop="name" label="管理员名称" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="aid" label="地区" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="real_name" label="真实姓名" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="price" label="消费金额（元）" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="pay_time" label="消费时间" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="num" label="消费内容" show-overflow-tooltip min-width="120"/>
            </template>
          </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="语音消费列表" name="second">
        <el-card shadow="never">
          <!-- 搜索表单 -->
          <!-- <el-form :model="table1.where" class="ele-form-search d-flex f-end"
            style="display:flex;flex-wrap: wrap;" @keyup.enter.native="$refs.table.reload()"
            @submit.native.prevent>
            <el-form-item label="省:" label-width="35px">
              <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
                placeholder='请选择省' class="input150" clearable>
                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                  :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" label-width="35px">
              <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)"
                placeholder='请选择市' class="input150" clearable>
                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                  :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="县市区:" label-width="65px">
              <el-select v-model="table.where.aid" placeholder='请选择县市区' @change="$forceUpdate()" class="input150 mr-20" clearable>
                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                  :label="option.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                class="ele-btn-icon">搜索</el-button>
              <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
            </el-form-item>
          </el-form> -->
          <!-- 数据表格 -->
          <ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
                          :stripe=true highlight-current-row>
            <template slot-scope="{index}">
              <el-table-column type="selection" width="45" align="center" fixed="left"/>
              <el-table-column type="index" :index="index" label="序号" width="60" align="center"
                               fixed="left" show-overflow-tooltip/>
              <el-table-column prop="real_name" label="管理员" show-overflow-tooltip min-width="140"/>
              <el-table-column prop="user_phone" label="用户" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="driver_phone" label="司机" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="area" label="地区" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="orderId" label="订单id" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="long_time" label="语音时长" show-overflow-tooltip min-width="120"/>
              <el-table-column prop="create_time" label="开始时间" show-overflow-tooltip min-width="120"/>
              <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
                <template slot-scope="{row}">
                  <el-link icon="el-icon-view" type="primary" :underline="false"
                           @click="showOrder(row)"
                           v-if="permission.includes('sys:voice:voiceVisibleView')">查看
                  </el-link>
                  <el-link icon="el-icon-notebook-2" type="primary" :underline="false"
                           @click="handleChat(row)" v-if="permission.includes('sys:voice:voiceChat')">聊天记录
                  </el-link>
                </template>
              </el-table-column>
            </template>
          </ele-data-table>


          <!-- 聊天记录 -->
          <el-dialog v-dialogDrag title="聊天记录" :visible.sync=" dialogVisibleAdd" class="records" @close='closeDialog'
                     width="600px" :destroy-on-close="true" :lock-scroll="false">
            <div class="charRecord-box">
              <div class="driverBox" style="padding:20px;width: 500px;">
                <div class="" style="width:80px;height:80px;">
                  <img style="width:60px;height:60px;border-radius:50%" :src="driverInfo.headimg"/>
                </div>
                <div class="">
                  <div class="" style="font-size:17px;margin:5px 0">{{ driverInfo.name }}</div>
                  <div class="" style="font-size:13px">{{ driverInfo.car_number }}</div>
                </div>
              </div>
              <div>
                <div class="charRecord-inner" v-for="(option,index) in charList" :key="option.id">
                  <!-- 时间 -->
                  <div class="w-100"
                       v-if="option.p_show==1 && option.type !== 33 && option.type !== 34 && option.content.S!=89 && option.content.S!=38 && option.content.S!=94 && option.content.S!=27">
                    <div class="charRecord-time">{{ option.create_time }}</div>
                  </div>
                  <!-- 系统消息-->
                  <div class="w-100"
                       v-if="(option.content.S==5 || option.content.S==100 || option.content.S==1 || option.content.S==20 || option.content.S==39) && option.p_show==1">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" src="../order/logo1.png">
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-title p-10">{{ option.content.M }}</div>
                    </div>
                  </div>
                  <!-- 左侧 完善目的地-->
                  <div class="w-100" v-if="option.content.S==27 && option.direction==3">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" src="../order/logo1.png">
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-title ">
                        <div class="">
                          <div class="m-10 mb-0">您可以完善一下目的地</div>
                          <div class="whiteAddress">{{ orderInfo.place_address }}</div>
                          <div class="addressBtn">
                            <span class="ignore">忽略</span>
                            <span class="submit">提交</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 左侧 语音-->
                  <div class="w-100 " v-if="option.content.S==21 && option.direction==2">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-title2 p-10 " :style="{width:option.bgWidth}"
                           @click="handlePlay(option.content.P.URL,option.id,index)"
                           v-if="option.isPlay==true ">
                        <audio class="pasuVideo" :src="option.content.P.URL"
                               :ref="'middle'+index">您的浏览器不支持
                        </audio>
                        <div class="lvoide1">
                          <img src="../../../assets/images/ic_b_yuyin@2x.png">
                        </div>
                        <div>{{ option.content.P.LONG }}''</div>
                      </div>
                      <div class="charRecord-title2 p-10" :style="{width:option.bgWidth}"
                           v-if="option.isPlay==false">
                        <audio class="pasuVideo" :src="option.content.P.URL"
                               :ref="'middle'+index">您的浏览器不支持
                        </audio>
                        <div class="lvoide1">
                          <img src="../../../assets/images/ic_b_yuyin.gif">
                        </div>
                        <div>{{ option.content.P.LONG }}''</div>
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 语音-->
                  <div class="w-100 " v-if="option.content.S==21 && option.direction==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title2 p-10 bgGreen " :style="{width:option.bgWidth}"
                           @click="handlePlay(option.content.P.URL,option.id,index)"
                           v-if="option.isPlay==true">
                        <audio class="pasuVideo" :src="option.content.P.URL"
                               :ref="'middle'+index">您的浏览器不支持
                        </audio>
                        <div style="color:#fff">{{ option.content.P.LONG }}''</div>
                        <div class="rvoide1">
                          <img src="../../../assets/images/yuyin-ico.png"
                               :ref="'changeImg'+index">
                        </div>

                      </div>
                      <div class="charRecord-title2 p-10 bgGreen" :style="{width:option.bgWidth}"
                           v-if="option.isPlay==false">
                        <audio class="pasuVideo" :src="option.content.P.URL"
                               :ref="'middle'+index">您的浏览器不支持
                        </audio>

                        <div style="color:#fff">{{ option.content.P.LONG }}''</div>
                        <div class="rvoide1">
                          <img src="../../../assets/images/yuyin-gif.gif">
                        </div>

                      </div>
                      <div class="rhh1"></div>
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>


                  <!-- 右侧 图片-->
                  <div class="w-100" v-if="option.content.S==22">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title1 p-10"
                           @click="hanlePicture(option.content.P.URL)">
                        <img class="" :src="option.content.P.URL" style="max-width: 370px;">
                      </div>
                      <div class="rhh"></div>
                      <div class="charRecord-image">
                        <img width='100px' height='100px' class="charHeadimg"
                             :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 视频-->
                  <div class="w-100" v-if="option.content.S==6">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title1 p-10">
                        <video width="100" height="100" controls>
                          <source :src="option.content.P.URL" type="video/mp4">
                        </video>
                      </div>
                      <div class="rhh"></div>
                      <div class="charRecord-image">

                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 地图-->
                  <div class="w-100" v-if="option.content.S==26 && option.direction==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-titleMap p-10">
                        <div class="amap-page-container">
                          <div :style="{width:'100%',height:'180px'}">
                            <!-- vid="amapDemo1" -->
                            <el-amap ref="map" :zoom="zoom"
                                     :center="option.content.P.POINT">
                              <el-amap-marker></el-amap-marker>
                            </el-amap>
                          </div>
                        </div>
                      </div>
                      <div class="rhh"></div>
                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 左侧 地图-->
                  <div class="w-100" v-if="option.content.S==26 && option.direction==2">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-titleMap p-10">
                        <div class="amap-page-container">
                          <div :style="{width:'100%',height:'180px'}">
                            <el-amap ref="map" :zoom="zoom"
                                     :center="option.content.P.POINT">
                              <el-amap-marker></el-amap-marker>
                            </el-amap>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                  <!-- 左侧 语音通话 对方已取消-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==3 && option.p_show==1">
                    <div class="charRecord-leftItem">
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>

                      <div class="charRecord-title2 p-10 disCenter voide">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phone3grey@2x.png">
                        </div>
                        <div>{{ option.content.P.voiceCallMessage }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 左侧 语音通话 已拒接-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==4 && option.p_show==1">
                    <div class="charRecord-leftItem">
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>

                      <div class="charRecord-title2 p-10 disCenter voide">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phone3grey@2x.png">
                        </div>
                        <div>{{ option.content.P.message }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 左侧 语音通话 未接听-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==5 && option.p_show==1">
                    <div class="charRecord-leftItem">
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>

                      <div class="charRecord-title2 p-10 disCenter voide">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phone3grey@2x.png">
                        </div>
                        <div>{{ option.content.P.voiceCallMessage }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 左侧 语音通话 通话时长-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==6">
                    <div class="charRecord-leftItem">
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>

                      <div class="charRecord-title2 p-10 disCenter voide">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phonegrey@2x.png">
                        </div>
                        <div>{{ option.content.P.message }}</div>
                      </div>
                    </div>
                  </div>


                  <!-- 右侧 语音通话 已取消-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==3 && option.p_show==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phone3@2x.png">
                        </div>
                        <div>{{ option.content.P.message }}</div>
                      </div>
                      <div class="rhh1"></div>
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 语音通话 对方已拒接-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==4 && option.p_show==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phone3@2x.png">
                        </div>
                        <div>{{ option.content.P.voiceCallMessage }}</div>
                      </div>
                      <div class="rhh1"></div>
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 语音通话 对方无应答-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==5 && option.p_show==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/call_ic_whitewait.png">
                        </div>
                        <div>{{ option.content.P.message }}</div>
                      </div>
                      <div class="rhh1"></div>
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 语音通话 通话时长-->
                  <div class="w-100"
                       v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6">
<!--                       v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6 && option.p_show==1">-->
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                        <div class=" mr-10">
                          <img src="../../../assets/images/voice/ic_phone@2x.png">
                        </div>
                        <div>{{ option.content.P.message }}</div>
                      </div>
                      <div class="rhh1"></div>
                      <div class="charRecordrightImage">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>

                  <!-- 左侧 订单账单-->
                  <div class="w-100" v-if="option.content.S==31">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" src="../order/logo1.png"/>
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-Paytitle">
                        <div class="w-30">
                          <div class="m-10 mb-0 text-center">订单账单</div>
                          <div class="m-10 mb-0 text-center">
                            <span class="price">{{ option.show_data.price }}</span>元
                          </div>
                          <div class="p-10 text-center payBox">
                            <div class="payTitle">行程车费</div>
                            <div class="payPrice">{{ option.show_data.price }}元</div>
                          </div>
                          <div class="p-10  payBox">
                            <div class="payTitle">零钱抵现</div>
                            <div class="payPrice"><span
                                style="color: #FF9D00;">-{{ option.show_data.bonus }}</span>元
                            </div>
                          </div>
                          <div class="p-10 payType">
                            <div>
                              <img src="../../../assets/images/share_ic_wechat@2x.png"
                                   style="float: left;" alt="" width="30px" height="30px;">
                              <span class="wxPay">微信支付</span>
                            </div>

                            <img src="../../../assets/images/ic_arrow@2x.png"
                                 style="float: right;margin-top: 3px;" alt="" width="10px"
                                 height="20px;">

                          </div>
                          <!--													<div class="p-10 payType borderTop">-->
                          <!--														<div>-->
                          <!--															<img src="../../../assets/images/ic_chat_pt_pay_alipay.png"-->
                          <!--																style="float: left;" alt="" width="30px" height="30px;">-->
                          <!--															<span class="wxPay">支付宝支付</span>-->
                          <!--														</div>-->

                          <!--														<img src="../../../assets/images/ic_arrow@2x.png"-->
                          <!--															style="float: right;margin-top: 3px;" alt="" width="10px"-->
                          <!--															height="20px;">-->
                          <!--													</div>-->
                          <div class="p-10 payType borderTop">
                            <div>
                              <img src="../../../assets/images/ic_pay@2x.png"
                                   style="float: left;" alt="" width="30px" height="30px;">
                              <span class="wxPay">线下支付</span>
                            </div>

                            <img src="../../../assets/images/ic_arrow@2x.png"
                                 style="float: right;margin-top: 3px;" alt="" width="10px"
                                 height="20px;">
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- 左侧 违约金账单-->
                  <div class="w-100" v-if="option.content.S==46">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" src="../order/logo1.png"/>
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-Paytitle">
                        <div class="w-30">
                          <div class="m-10 mb-0 text-center">违约金账单</div>
                          <div class="m-10 mb-0 text-center">
                            <span class="price">{{ option.content.P.PRICE }}</span>元
                          </div>
                          <div class="p-10 payType">
                            <div>
                              <img src="../../../assets/images/share_ic_wechat@2x.png"
                                   style="float: left;" alt="" width="30px" height="30px;">
                              <span class="wxPay">微信支付</span>
                            </div>

                            <img src="../../../assets/images/ic_arrow@2x.png"
                                 style="float: right;margin-top: 3px;" alt="" width="10px"
                                 height="20px;">

                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- 右侧 违约金支付成功-->
                  <div class="w-100" v-if="option.content.S==36 && option.p_show==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-Paytitle ">
                        <div>
                          <div class="paySuccess">支付成功</div>
                          <div class="money">
											<span
                          style="color:#84B625;font-size:25px;text-align:center">{{ option.content.PRICE }}</span>元
                          </div>
                        </div>

                      </div>
                      <div class="rhh"></div>
                      <div class="charRecordRightImage">
                        <img class="charHeadimg" :src="option.user.headimg"/>
                      </div>

                    </div>
                  </div>
                  <!-- 右侧 支付成功-->
                  <div class="w-100" v-if="option.content.S==32 && option.p_show==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-Paytitle ">
                        <div>
                          <div class="paySuccess">支付成功</div>
                          <div class="money">
														<span
                                style="color:#84B625;font-size:25px;text-align:center">{{ option.content.PRICE }}</span>元
                          </div>
                          <div class="otherPay">红包另支付0元</div>
                        </div>

                      </div>
                      <div class="rhh"></div>
                      <div class="charRecordRightImage">
                        <img class="charHeadimg" :src="option.user.headimg"/>
                      </div>

                    </div>
                  </div>

                  <!-- 左侧 被动红包 -->
                  <div class="w-100" v-if="option.content.S==72">
                    <div class="charRecord-leftItem">
                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.driver.headimg">
                      </div>
                      <div class="hh"></div>
                      <div class="charRecord-Paytitle">
                        <div class="">
                          <div class="text-align pbt-10">对话红包</div>
                          <div class="bill-box">
                            <div class="">红包金额(元)</div>
                            <div class=""><span>{{ option.content.P.PRICE }}</span>元</div>
                          </div>
                          <div class="btn">
                            <div class="btn-ignore">驳回</div>
                            <div class="line"></div>
                            <div class="btn-ignore">发红包</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 被动红包-->
                  <div class="w-100" v-if="option.content.S==69">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-Paytitle">
                        <div class="">
                          <div class="text-align pbt-10">对话红包</div>
                          <div class="bill-box">
                            <div class="">红包金额</div>
                            <div class=""><span>0.01</span>元</div>
                          </div>
                          <div class="btn">
                            <div class="btn-ignore text-align">已驳回</div>
                          </div>
                        </div>
                      </div>
                      <div class="rhh"></div>
                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 未领取主动红包-->
                  <div class="w-100" v-if="option.content.S==71 && option.content.H==1">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title3">
                        <img src="../../../assets/images/bg_hb1@2x.png"
                             style="position:relative;width:100%;height:100%"/>

                        <div class="red-box" style="position:absolute;top:30px">
                          <div>
                            <img class="package"
                                 src="../../../assets/images/img_hb1@2x.png"/>
                          </div>
                          <div class="">
                            <div class="">主动红包，司机可领取</div>
                            <div class="font--9">未领取</div>
                          </div>
                        </div>
                        <div class="address-box" style="position:absolute;top:64px">
                          <div class="">
                            <img class="weizhi"
                                 src="../../../assets/images/hb_logo@2x.png"/>
                          </div>
                          <div class="font--9">咱县打车</div>
                        </div>
                      </div>

                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                  <!-- 右侧 已领取主动红包-->
                  <div class="w-100" v-if="option.content.S==71 && option.content.H==2">
                    <div class="charRecord-rightItem">
                      <div class="charRecord-title3">
                        <img src="../../../assets/images/bg_hb2@2x.png"
                             style="position:relative;width:100%;height:100%"/>

                        <div class="red-box" style="position:absolute;top:30px">
                          <div>
                            <img class="package"
                                 src="../../../assets/images/img_hb2@2x.png"/>
                          </div>
                          <div class="">
                            <div class="">主动红包，司机可领取</div>
                            <div class="font--9">未领取</div>
                          </div>
                        </div>
                        <div class="address-box" style="position:absolute;top:64px">
                          <div class="">
                            <img class="weizhi"
                                 src="../../../assets/images/hb_logo@2x.png"/>
                          </div>
                          <div class="font--9">咱县打车</div>
                        </div>
                      </div>

                      <div class="charRecord-image">
                        <img class="charHeadimg" :src="option.user.headimg">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ampBox" :style="{display:ampDisplay==true?'block':'none'}" @click="handleClose">
              <img :src="ampSrc">
            </div>
          </el-dialog>
          <!-- 对话框：查看 -->
          <el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogFormVisibleView" width="1000px"
                     :destroy-on-close="true" :lock-scroll="false">
            <el-card shadow="never">
              <el-form :model="orderDetail" label-width="140px">
                <div style="display: flex;">
                  <div style="width:50%;box-sizing: border-box;padding: 0 50px 0 10px;border-right: 1px solid #C6C6C6;">
                    <el-form-item label="发单时间：" prop="create_time">
                      {{ orderDetail.create_time*1000 | toDateString }}
                    </el-form-item>
                    <el-form-item label="订单号：" prop="order_id">
                      {{orderDetail.order_id}}
                    </el-form-item>
                    <el-form-item label="订单类型：" prop="type_name">
                      {{orderDetail.type_name}}
                    </el-form-item>
                    <el-form-item label="发单起点所在地：" prop="place_address">
                      {{orderDetail.place_address}}
                    </el-form-item>
                    <el-form-item label="发单终点所在地：" prop="target_address">
                      {{orderDetail.target_address}}
                    </el-form-item>
                    <el-form-item label="用户语音：" prop="audio_length">
                      {{orderDetail.audio_length}}
                    </el-form-item>
                    <el-form-item label="发单用户姓名：" prop="place_name" class="w-120">
                      {{orderDetail.place_name}}
                    </el-form-item>
                    <el-form-item label="发单用户手机号：" prop="place_phone">
                      {{orderDetail.place_phone}}
                    </el-form-item>
                    <el-form-item label="起点：" prop="place_address">
                      {{orderDetail.place_address}}
                    </el-form-item>
                    <el-form-item label="终点：" prop="target_address">
                      {{orderDetail.target_address}}
                    </el-form-item>
                    <el-form-item label="接单司机：" prop="driver_name">
                      {{orderDetail.driver_name}}
                    </el-form-item>
                  </div>
                  <div style="width:50%;box-sizing: border-box;">
                    <el-form-item label="司机手机号：">
                      {{orderDetail.driver_phone}}
                    </el-form-item>
                    <el-form-item label="手续费：" prop="handling_fee">
                      {{orderDetail.handling_fee}}
                    </el-form-item>
                    <el-form-item label="优惠券减免：" prop="discount_money">
                      {{orderDetail.discount_money}}
                    </el-form-item>
                    <el-form-item label="零钱抵扣：" prop="bonus">
                      {{orderDetail.bonus}}
                    </el-form-item>
                    <el-form-item label="订单金额：" prop="order_price">
                      {{orderDetail.order_price}}
                    </el-form-item>
                    <el-form-item label="客户应付金额(元)：" prop="price">
                      {{orderDetail.price}}
                    </el-form-item>
                    <el-form-item label="抢单时间：" prop="receive_time">
                      {{ orderDetail.receive_time*1000 | toDateString }}
                    </el-form-item>
                    <el-form-item label="完成时间：" prop="complete_time">
                      {{ orderDetail.complete_time*1000 | toDateString }}
                    </el-form-item>
                    <el-form-item label="支付方式：" prop="payment">
                      {{(orderDetail.payment==1?'微信':orderDetail.payment==2?'支付宝':'线下支付')}}
                    </el-form-item>
                    <el-form-item label="订单状态：" prop="status_name">
                      {{orderDetail.status_name}}
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </el-card>
          </el-dialog>
        </el-card>
      </el-tab-pane>

    </el-tabs>
    <!--购买短信套餐-->
    <el-dialog v-dialogDrag title="购买语音套餐" :visible.sync="dialogVisiblePlans" custom-class="ele-dialog-form"
               :lock-scroll="false" class="plans" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false" :destroy-on-close="true">
      <el-card shadow="never">
        <div class="plansBox">
          <div class="plansItem" style="width: 25%;float: left;">
            <div class="plansInner " :class="active == 1? 'greenborder':''"
                 @click="handleChange(1,editFive.num1,editFive.price1)">
              <div class="plansTitle">{{ editFive.num1 }}分钟语音套餐</div>
              <div class="plansMoney">￥{{ editFive.price1 }}</div>
              <div class="plansCluse">包含：{{ editFive.num1 }}分钟</div>
            </div>

          </div>
          <div class="plansItem" style="width: 25%;float: left;">
            <div class="plansInner " :class="active == 2? 'greenborder':''"
                 @click="handleChange(2,editFive.num2,editFive.price2)">
              <div class="plansTitle">{{ editFive.num2 }}分钟语音套餐</div>
              <div class="plansMoney">￥{{ editFive.price2 }}</div>
              <div class="plansCluse">包含：{{ editFive.num2 }}分钟</div>
            </div>

          </div>
          <div class="plansItem" style="width: 25%;float: left;">
            <div class="plansInner " :class="active == 3? 'greenborder':''"
                 @click="handleChange(3,editFive.num3,editFive.price3)">
              <div class="plansTitle">{{ editFive.num3 }}分钟语音套餐</div>
              <div class="plansMoney">￥{{ editFive.price3 }}</div>
              <div class="plansCluse">包含：{{ editFive.num3 }}分钟</div>
            </div>

          </div>
          <div class="plansDeduct">支付方式： <span>账户余额扣除</span></div>
          <div class="plansDeduct">支付金额（元）：
            <span>￥{{ price }}</span>
            <!-- <span v-if="price == 0">￥{{editFive.pay_price}}</span>
            <span v-else>￥{{price}}</span> -->
          </div>
        </div>

      </el-card>
      <div slot="footer">
        <!-- <template slot-scope="{option}"> -->
        <!-- <el-button @click="dialogVisiblePlans=false">取消</el-button> -->
        <el-button type="primary" @click="purchaseSave(num,price,editFive.id)">确认购买</el-button>
        <a style="" @click="dialogVisiblePlans=false" class="closeBtn">关闭</a>
        <!-- </template> -->

      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: 'SysUser',

  data () {
    return {
      currIndex: 0,
      zoom: 16,
      center: [114.397169, 30.50576],
      charList: [],
      open: {},
      table: {
        url: '/voice/voiceRecharge',
        where: {}
      }, // 表格配置
      table1: {
        url: '/voice/consume',
        where: {}
      }, // 语音消费列表表格配置
      table2: {
        url: '/voice/voiceSwitch',
        where: {}
      }, // 语音开关表格配置
      choose: [], // 表格选中数据
      editForm: {}, // 表单数据
      orderDetail: {},
      provArr: [],
      cityArr: [],
      districtArr: [],
      urgentList: [],
      imageUrl: false,
      headImg: '',
      activeName: 'first',
      dialogVisibleAdd: false,
      dialogVisibleOpen: false,
      dialogFormVisibleView: false,

      sizeform: {},
      voiceRecharge: {},
      aa: true,
      ampDisplay: false,
      ampSrc: '',
      audioArr: [],
      driverInfo: {},
      orderInfo: {},
      audio: '',
      dialogVisiblePlans: false,
      editFive: {},
      active: 1,
      price: 0,
      num: 0,
    }
  },
  created () {
    this.init()

  },

  computed: {
    ...mapGetters(['permission']),
  },
  mounted () {},
  methods: {
    // 初始化 接口
    init () {
      this.$http.get('/common/province_list').then(res => {
        let data = JSON.parse(res.data)
        this.provArr = data
      })

      this.$http.get('/voice/voiceModel').then(res => {
        let data = res.data
        this.sizeform = data.data
      })
      this.$http.get('/voice/voiceRecharge').then(res => {
        this.voiceRecharge = res.data
      })
    },
    form () {},
    // 开启
    handleopen () {
      const loading = this.$loading({
        lock: true
      })
      this.$http.post('/voice/voiceOpen', this.open).then(res => {
        loading.close()
        let data = res.data
        if (data.code === 1) {
          this.$message({
            type: 'success',
            message: '开启成功'
          })
          this.$refs.table2.reload()
        } else {
          this.$message.error(data.msg)
        }
      }).catch(e => {
        loading.close()
        this.$message.error(e.message)
      })
    },
    handleChat (row) {
      //console.log(row)
      this.dialogVisibleAdd = true
      const loading = this.$loading({
        lock: true,
        target: document.querySelector('.records')
      })
      // this.$http.post('/order/chat_log',{id:row.id}).then(res=>{
      this.$http.post('/order/chat_log', {
        id: row.order_id
      }).then(res => {
        var data = res.data
        loading.close()
        data.log.forEach(function (item) {
          item.isTimeShow = 1
          if (item.content.S == 21) {
            item.isPlay = true
            if (item.content.P.LONG == 1) {
              item.bgWidth = '8rem'
            }
            item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
          }
          if (item.content.S == 26) {
            item.content.P.POINT = item.content.P.POINT.split(',')
            //console.log(item.content.P.POINT)

          }
        })
        for (var i = 0; i < data.log.length; i++) {
          for (var j = i + 1; j < data.log.length - 1; j++) {
            if (data.log[i].create_time == data.log[j].create_time) {
              data.log[j].isTimeShow = 0 //不显示时间
            } else {
              data.log[j].isTimeShow = 1
            }
          }
        }
        this.charList = data.log

        this.driverInfo = data.driver
        this.orderInfo = data.order_info
      })
    },
    showOrder (row) {
      //console.log(row)
      this.dialogFormVisibleView = true

      this.$http.post('/order/detail', {
        id: row.order_id
      }).then(res => {
        var data = res.data.data
        this.orderDetail = data

      })
    },
    hanlePicture (src) {
      this.ampDisplay = true
      this.ampSrc = src
    },
    handleClose () {
      this.ampDisplay = false
    },
    handlePlay () {
      let that = this
      let audio = new Audio('https://zszx.zdache.cn:8441/dingdong.aac')
      audio.play()
      this.aa = false
      audio.addEventListener('ended', function () {
        that.aa = true
      }, false)
    },
    handleChange (active, num, price) {
      this.active = active
      this.price = price
      this.num = num
      // this.editFive.forEach(function(item) {
      // 	if (item.id == id) {
      // 		item.greenborder = true
      // 	} else {
      // 		item.greenborder = false
      // 	}
      // })
    },
    /**
     *选择省
     **/
    handleChangeProv (e) {
      //console.log(e)
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.open.cid = ''
        this.open.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity (e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.open.aid = ''
        })
      } else {
        this.open.pid = ''
        this.open.aid = ''
      }
    },
    /**
     *选择省
     **/
    handleChangeProv2 (e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table2.where.cid = ''
        this.table2.where.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity2 (e) {
      /** 获取被选市的cid**/
      let cid = ''
      this.cityArr.forEach(function (item) {
        if (item.cid == e) {
          cid = item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list', {
        cid: cid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.districtArr = data
        /** 选择省份清空县**/
        this.table2.where.aid = ''
      })
    },
    save () {
      const loading = this.$loading({
        lock: true
      })
      let sizeform = this.sizeform
      let param = {
        num1: sizeform.num1,
        price1: sizeform.price1,
        num2: sizeform.num2,
        price2: sizeform.price2,
        num3: sizeform.num3,
        price3: sizeform.price3
      }
      this.$http.post('/voice/voiceTempData', param).then(res => {
        loading.close()
        let data = res.data
        if (data.code == 1) {
          this.$message({
            message: data.msg,
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)

      })
    },
    closeDialog () {
      var middlePlay = document.querySelectorAll('.pasuVideo')
      for (var i = 0; i < middlePlay.length; i++) {
        middlePlay[i].pause()
        middlePlay[i].currentTime = 0
      }
    },
    handleClick () {},
    switch_start (row) {
      if (row.type == 1) {
        this.$confirm('确定关闭语音通话吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/voice/openingType', {
            id: row.id,
            type: 2
          }).then(res => {
            loading.close()
            let data = res.data
            if (data.code === 1) {

              this.$message({
                type: 'success',
                message: '关闭成功'
              })
              this.$refs.table2.reload()
            } else {
              this.$message.error(data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      } else {
        this.$confirm('确定启用语音通话吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })

          this.$http.post('/voice/openingType', {
            id: row.id,
            type: 1
          }).then(res => {
            let data = res.data
            loading.close()
            if (data.code === 1) {
              this.$message({
                type: 'success',
                message: '启用成功'
              })
              this.$refs.table2.reload()
              this.switchStart = '关闭'
            } else {
              this.$message.error(data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      }
    },

    // 购买短信套餐
    purchaseSave (num, price, id) {
      const loading = this.$loading({lock: true, target: document.querySelector('.plans')})
      this.$http.post('/Voice/voice_buy', {
        num: num,
        price: price,
        id: this.voiceRecharge.admin_id
      }).then(res => {
        if (res.data.code === 0) {
          // const loading = this.$loading({ lock: true,target: document.querySelector(".d-flexspabet")});
          this.dialogVisiblePlans = false
          this.$message({
            type: 'success',
            message: '购买成功'
          })
          this.init()
          this.$refs.table.reload()
        } else {
          // this.dialogVisiblePlans = false
          loading.close()
          this.$message.error({
            type: 'error',
            message: res.data.msg
          })
        }
      })
    },

    // 短信套餐展示框
    handlePurchase () {
      this.dialogVisiblePlans = true
      const loading = this.$loading({
        lock: true,
        target: document.querySelector('.plans')
      })
      this.$http.get('/Voice/voiceModel',).then(res => {
        let data = res.data

        loading.close()
        // data.forEach(function(item) {
        //   if (item.id == 1) {
        //     item.greenborder = true
        //   } else {
        //     item.greenborder = false
        //   }
        //
        // })
        this.editFive = data.data
        this.editFive.pay_price = data.data.price1
        this.price = this.editFive.price1
        this.num = this.editFive.num1
        //console.log(data.data)
      })
    },

  }
}
</script>
<style>
.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

.el-form-item-label {
  width: 20%;
  text-align: left
}


.mr-10 {
  margin-right: 10px;
}

.el-date-editor /deep/ .el-input,
.el-date-editor /deep/ .el-input__inner {
  width: 70% !important;
}
</style>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.ele-body {
  padding: 15px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

/deep/ .el-tabs__item {
  padding: 0 20px !important;
  font-size: 15px;
}

/deep/ .el-tabs__nav-scroll {
  background: white !important;
  padding-top: 10px;
}

.boxTitle {
  padding: 8px 0
}

.orderImgBox {
  margin-left: 10px;
  height: 80px;
}

.orderImgBox .orderImgBoxText {
  font-size: 36px;
  padding-left: 20px
}

.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}

.innerBox {
  width: 40%;
  margin-left: 100px;
  padding-top: 20px;
  border-bottom: 1px rgb(240, 240, 240) solid;
}

.innerBoxTo {
  width: 40%;
  margin-left: 100px;
  padding-top: 20px;
}

.innerTitle {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 13px;
}

.inputWidth {
  width: 100%;
}

/deep/ .el-dialog {
  margin-top: 60px !important;
}


.plansBox {
  width: 100%;
  border: 1px solid rgb(240, 240, 240);
  margin: auto
}

.plansItem {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 0 25px;
}

.plansInner {
  padding: 0 10px;
  border: 1px solid rgb(240, 240, 240);
  margin: 20px 0;
  width: 100%
}

.plansInner1 {
  margin: 20px 0;
  width: 18%
}

.plansCon {
  padding: 0 10px 10px;
  border: 1px solid rgb(240, 240, 240);
  width: 100%;
  margin-bottom: 20px;
  height: 310px
}

.plansTitle {
  color: #33cc99;
  padding: 30px 0 20px 0;
}

.plansMoney {
  color: #ff892e;
  padding: 0 20px 20px;
  border-bottom: 1px solid rgb(240, 240, 240);
  width: 40%;
  margin: auto;
}

.plansCluse {
  padding: 20px;
}

.plansNum {
  padding-bottom: 40px;
}

.plansMiaoshu {
  padding: 60px 0 0;
  color: #33cc99
}

.plansDeduct {
  padding-left: 50px;
  padding-bottom: 20px;
  float: left;
  width: 100%;
}

.plansDeduct span {
  color: #ff892e;
  padding-left: 30px
}

.plansContent {
  padding: 30px 0;
  height: 120px
}

.greenborder {
  border: 1px solid #33cc99 !important
}

.closeBtn {
  cursor: pointer;
  display: inline-block;
  height: 36px;
  width: 90px;
  text-align: center;
  line-height: 36px;
  margin-left: 10px;
  background: red;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  text-decoration: none;
}
</style>
